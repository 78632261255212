import Cookies from 'js-cookie';

/**
 * @author: Grazielle Conceição
 * @since: 2024-07-09
 * @returns 
 */
function getUserJwt() {
    const jwtCookies = Cookies.get('jwt');
    const refreshCookies = Cookies.get('refreshJwt');

    if (jwtCookies) {
        return {
            jwt: jwtCookies,
            refresh: refreshCookies
        }
    }

    return null;
}

/**
 * @author: Grazielle Conceição
 * @since: 2024-07-09
 */
function clearAuthenticationCookies() {
    const isLocalHost = process.env.REACT_APP_NODE_ENV === 'development';

    Cookies.remove('jwt', { domain: !isLocalHost ? '.voltaliainnovation.com' : undefined });
    Cookies.remove('refreshJwt', { domain: !isLocalHost ? '.voltaliainnovation.com' : undefined });
}

/**
 * @author: Grazielle Conceição
 * @since: 2024-07-09
 * @description Function that sets the user's jwt and refresh jwt in Cookies
 * @param jwt 
 * @param refresh 
 */
function insertsUserTokensInCookies(jwt, refresh) {
    const isLocalHost = process.env.REACT_APP_NODE_ENV === 'development';

    Cookies.set('jwt', jwt, {
        domain: !isLocalHost ? '.voltaliainnovation.com' : undefined,
        secure: true
    });

    Cookies.set('refreshJwt', refresh, {
        domain: !isLocalHost ? '.voltaliainnovation.com' : undefined,
        secure: true
    });
}

export {
    getUserJwt,
    clearAuthenticationCookies,
    insertsUserTokensInCookies
}