/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect } from "react";
import { getUserJwt } from "./cookies";
import { getUserByJwt } from "./data";
import { useAuthContext } from "../../contexts/auth";

export function AnalyticsAuthentication(props) {
  const { updateUser } = useAuthContext();

  useEffect(() => {
    authenticate();
  }, []);

  async function authenticate() {
    const tokens = getUserJwt();

    if (tokens?.jwt) {
      const user = await getUserByJwt();

      if (user?.id) {
        updateUser(user);

        props.history.push("/dashboard");
      }
    } else {
      console.log("unauthenticate");
      window.location.href = "https://analytics.voltaliainnovation.com";
    }
  }

  return (
    <>
      <br />
      <h1 align="center">Autenticação</h1>
      <hr />
    </>
  );
}
