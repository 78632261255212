import axios from 'axios'
import createAuthRefreshInterceptor from "axios-auth-refresh";

import urlApi from './url'
import { getUserJwt } from './analytics/cookies';
import { refreshAuthLogic } from './analytics/data';

const api = axios.create({
  baseURL: urlApi,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

api.interceptors.request.use(
  (config) => {
    const tokens = getUserJwt();

    if (tokens?.jwt) {
      config.headers['Authorization'] = `${tokens.jwt}`
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

createAuthRefreshInterceptor(api, (failedRequest) => refreshAuthLogic(failedRequest, ''), {
  pauseInstanceWhileRefreshing: true
});

const authApi = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API,
  headers: {
    'Content-Type': 'application/json',
  }
});

authApi.interceptors.request.use(
  (config) => {
    const tokens = getUserJwt();

    if (tokens?.jwt) {
      config.headers['Authorization'] = `Bearer ${tokens.jwt}`
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

createAuthRefreshInterceptor(authApi, (failedRequest) => refreshAuthLogic(failedRequest, 'Bearer'), {
  pauseInstanceWhileRefreshing: true
});

export default api

export {
  authApi
}