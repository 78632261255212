import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContextProvider } from './contexts/auth';
import { AnalyticsAuthentication } from './services/analytics/auth';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

class App extends Component {

  render() {
    return (
      <AuthContextProvider>
        <HashRouter basename="/">
          <React.Suspense fallback={loading}>
            <Switch>
              <Route path="/authentication" component={AnalyticsAuthentication} />
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </AuthContextProvider>
    );
  }
}

export default App;
