import axios from "axios";
import { authApi } from "../../api";
import { clearAuthenticationCookies, getUserJwt, insertsUserTokensInCookies } from "../cookies";

/**
 * @author: Grazielle Conceição
 * @since: 2024-07-09
 * @returns 
 */
async function getUserByJwt() {
    const { data: response } = await authApi.get('/users/token/');

    return response;
}

/**
 * @author: Grazielle Conceição
 * @since: 2024-07-09
 * @description: Function that gets new JWT from refresh token
 * @param model 
 * @returns 
 */
async function generatesNewJwtFromRefreshToken(model) {
    const { data: response } = await axios.post(`${process.env.REACT_APP_AUTH_API}/token/refresh/`, model);

    return response;
}

/**
 * @author: Grazielle Conceição
 * @since: 2024-06-25
 * @param failedRequest 
 */
const refreshAuthLogic = async (failedRequest, preffixAuthorization) => {
    const tokens = getUserJwt();

    if (tokens?.refresh && tokens?.jwt) {
        const refreshToken = tokens?.refresh;

        const model = {
            refresh: refreshToken
        };

        await generatesNewJwtFromRefreshToken(model).then((response) => {
            const { access } = response;

            insertsUserTokensInCookies(access, refreshToken);

            failedRequest.response.config.headers['Authorization'] = `${preffixAuthorization} ${access}`;

            return Promise.resolve()
        }).catch(() => {
            clearAuthenticationCookies();
            console.log('refresh token expired')
            window.location.href = "https://analytics.voltaliainnovation.com";
        });
    } else {
        window.location.href = "https://analytics.voltaliainnovation.com";
    }
}

export {
    getUserByJwt,
    generatesNewJwtFromRefreshToken,
    refreshAuthLogic
}