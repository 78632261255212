/* eslint-disable react/react-in-jsx-scope */
import React, { createContext, useContext, useState } from "react";

export const AuthContext = createContext({});

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);

  function updateUser(user) {
    if (user) {
      setUser(user);
    }
  }

  return (
    <AuthContext.Provider value={{ user, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthContextProvider");
  }

  return context;
};

export { useAuthContext };
